<template>
    <div class="text-center">
        <img class="closed-image" src="../assets/img/empty-table.jpg" alt="" />

        <div class="my-5 sorry">
            <h1 class="sorry-zone">Sorry...</h1>
            <p class="content-zone text-bold">The table is temporarily out of service.</p>

            <p>Please double check with the store or try another table</p>
        </div>

        <footer>
            <img src="" />
            <div class="copyright">&copy; {{ thisYear }} minitable tech all rights reserved</div>
        </footer>
    </div>
</template>

<script>
import { mapState, mapActions, mapMutations, mapGetters } from 'vuex';
export default {
    name: 'ClosedTable',
    data() {
        return {
            sid: this.$route.query.sid,
            tid: this.$route.query.tid
        }
    },
    created() {
        if (this.sid && this.tid) {
            this.checkClosedReason({
                sid: this.sid,
                tid: this.tid,
                router: this.$router
            });
        }
    },
    computed: {
        thisYear() {
            return new Date().getFullYear();
        }
    },
    methods: {
        ...mapActions({
            checkClosedReason: 'closed/checkClosedReason',
        })
    }
};
</script>

<style lang="scss" scoped>
.closed-image {
    width: 350px;
}

h1 {
    color: var(--themeColor);
    font-size: 24px;
}

.sorry {
    p {
        font-size: 18px;
    }
}

.copyright {
    text-transform: uppercase;
}
</style>
